<template>
  <layout :loading="loading">
    <template v-slot:content>
      <router-view @setLoading="loading = $event"/>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";

export default {
  components: { Layout },
  data: () => ({
    loading: false
  }),
}
</script>
